<template>
    <div>
        <pharmaceutics-component/>
        <footer-component/>
    </div>
</template>

<script>
    import PharmaceuticsComponent from "@/components/pharmaceutics/PharmaceuticsComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Pharmaceutics",
        title: "Farmacias | Turismo BC",
        components: {FooterComponent, PharmaceuticsComponent}
    }
</script>

<style scoped>

</style>